import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Index from './pages/index'
import Maintainance from './pages/maintainance'
import Login1 from './pages/auth/login-1'
import SignUp from './pages/auth/signUp'
import Profile from './pages/auth/details'
import Verify from './pages/auth/verification'
import Confirm from './pages/auth/confirm'
import PrivateRoute from './routes/protectedRoutes'
import Logout from './pages/auth/forms/logout'
import UpdateForm from './pages/auth/update'
import SupportPage from './pages/overview/support'
import Packages from './pages/investing/packages'
import Deposits from './pages/transactions/deposits'
import Withdrawals from './pages/transactions/withdrawals'
import Transfers from './pages/transactions/transfers'
import Refferals from './pages/investing/refferals'
import Dashboard from './pages/overview/dashboard'
import Investments from './pages/investing/investments'
import Refferal from './pages/refferral/index'
import Landing from './pages/landing/index'
import Inbox from './pages/overview/inbox'
const Routes = () => {
  return (
    <Switch>
      {/* <Route path="/login">
        <Login1 />
      </Route>
      <Route path="/signup">
        <SignUp />
      </Route>


      <Route path="/auth/confirm">
        <Confirm />
      </Route>
      <Route path="/auth/profile">
        <Profile />
      </Route>
      <Route path="/auth/verify">
        <Verify />
      </Route>
      <Route path="/logout">
        <Logout />
      </Route> */}
      <Route exact path="/">
        <Landing />
      </Route>
      {/* <Route exact path="/landing-demo">
        <Landing />
      </Route> */}
      <Route exact path="/invest">
        <Refferal />
      </Route>


{/* 
      <PrivateRoute exact path="/app">
        <Dashboard />
      </PrivateRoute>
      <PrivateRoute path="/app/wallets" exact>
      </PrivateRoute>
      <PrivateRoute path="/app/support" exact>
        <SupportPage />
      </PrivateRoute>
      <PrivateRoute path="/app/inbox" exact>
        <Inbox />
      </PrivateRoute>

      <PrivateRoute path="/app/investments" exact>
        <Investments />
      </PrivateRoute>
      <PrivateRoute path="/app/packages" exact>
        <Packages />
      </PrivateRoute>
      <PrivateRoute path="/app/refferals" exact>
        <Refferals />
      </PrivateRoute>

      <PrivateRoute path="/app/deposists" exact>
        <Deposits />
      </PrivateRoute>
      <PrivateRoute path="/app/withdrawals" exact>
        <Withdrawals />
      </PrivateRoute>
      <PrivateRoute path="/app/transfers" exact>
        <Transfers />
      </PrivateRoute>

      <PrivateRoute path="/app/settings" exact>
        <UpdateForm />
      </PrivateRoute> */}

      <Route component={Index} />
    </Switch>
  )
}
export default Routes
