import React, { useEffect, useState } from 'react'
import ErrorPage from '../../error-page'
import Loader from '../../../components/loader'
import Alert from '../../../components/alerts'
import { useAuth } from '../../../hooks/useAuth'
import { useCollection } from 'react-firebase-hooks/firestore'
import firebase from 'firebase'
import CalculatorInput from './calculator_input'
import PaymentMethods from './payment_methods'
import { calculateNow, investNow } from '../../../services/functions'
import { useHistory } from 'react-router'

const Calculator = () => {
  const [value, loading, error] = useCollection(firebase.firestore().collection(`packages`).orderBy('min_invest', 'desc'), {})

  try {
    return (<div>
      {error && <ErrorPage />}
      {loading && <Loader />}
      {value && <CalWidget data={value.docs} />}
    </div>)
  } catch (error) {
    console.log(error)
    return <Alert
      color="bg-transparent border-red-500 text-red-500 border-red-700 border font-bold"
      borderLeft
      raised>
      An error occured. Please try again later. (Error Code : OIx3S)
    </Alert>
  }
}

export default Calculator

const CalWidget = ({ data }) => {
  const [newD, setNewD] = useState(null)
  const [amount, onUpdate] = useState(null)
  useEffect(() => {
    if (amount != null) {
      setNewD(calculateNow(amount, data))
    }
  }, [amount])

  return (
    <div>
      <CalculatorInput onUpdataing={onUpdate} />
      {(newD && <CalPackage data={newD} />)}
    </div>
  )
}

const CalPackage = ({ data }) => {
  const { user } = useAuth()
  const history = useHistory()
  const onInvestHandler = async () => {
    setLoading(true)
    const res = await investNow(user, data, '', paymentData)
    if (res) {
      history.push('/app/investments')
    }
    setLoading(false)
  }
  const [paymentData, onUpdataing] = useState({})
  const [loading, setLoading] = useState(false)

  return (
    <div className="p-2 md:p-4 m-2">
      <div className=" p-4">
        <div className="lg:w-1/2 mx-auto rounded-lg p-8  text-left bg-white shadow-xl  transition duration-200 ease-in-out hover:-translate-y-2 transform cursor-pointer">
          <div className="my-4 text-white"><p>This is our suggesstion for you!</p></div>
          <div className="flex justify-between p-4 border-b"><p>Package</p>  <p className="font-bold text-right">{data['name']}</p></div>
          <div className="flex justify-between p-4 border-b"><p>Investment</p>  <p className="font-bold text-right">{data['min_invest']} USD</p></div>
          <div className="flex justify-between p-4 border-b"><p>Duration</p>  <p className="font-bold" text-right>{data['duration']} Months</p></div>
          <div className="flex justify-between p-4 border-b"><p>Interest</p>  <p className="font-bold text-right">{data['interest']} %</p></div>
          <div className="flex justify-between p-4 border-b"><p>Interest Amount</p>  <p className="font-bold text-right">{data['min_invest'] / 100 * data['interest']} USD</p></div>
          <div className="flex justify-between p-4 border-b font-extrabold"><p>Total Redeemable</p>  <p className="font-extrabold text-right">{parseFloat(data['min_invest'] / 100 * data['interest']) + parseFloat(data['min_invest'])} USD</p></div>
          <p className="text-center mt-2">This package will hold your money for <span className="font-bold">{data['duration']} months</span> and you'll recieve <span className="font-bold">{data['interest']}%</span> after the <span className="font-bold">{data['duration']} months</span>  ends.</p>

        </div>
      </div>
    </div>
  )
}

