import React from 'react'
import { FaFileInvoice } from 'react-icons/fa'
import {
  FiActivity,
  FiArrowDown,
  FiArrowDownCircle,
  FiArrowDownRight,
  FiArrowUp,
  FiArrowUpCircle,
  FiBox,
  FiCompass,
  FiHelpCircle,
  FiPhone,
  FiSettings,
  FiSmartphone,
  FiUpload,
  FiUser,
  FiUsers,
} from 'react-icons/fi'

const initialState = [
  {
    title: 'Overview',
    items: [
      {
        url: '/app',
        icon: <FiCompass size={20} />,
        title: 'Dashboard',
        items: []
      },
      {
        url: '/app/inbox',
        icon: <FaFileInvoice size={20} />,
        title: 'Inbox',
        items:[]
      },
    ]
  },
  {
    title: 'Investing',
    items: [
      {
        url: '/app/investments',
        icon: <FiActivity size={20} />,
        title: 'Investments',
        items:[]
      },
      {
        url: '/app/packages',
        icon: <FiBox size={20} />,
        title: 'Packages',
        items:[]
      },
      {
        url: '/app/refferals',
        icon: <FiUsers size={20} />,
        title: 'Refferals',
        items:[]
      },
    ]
  },
  {
    title: 'Transactions',
    items: [
      {
        url: '/app/deposists',
        icon: <FiArrowUp size={20} />,
        title: 'Deposists',
        items:[]
      },
      {
        url: '/app/withdrawals',
        icon: <FiArrowDown size={20} />,
        title: 'Withdrawals',
        items:[]
      },
      {
        url: '/app/transfers',
        icon: <FiArrowDownRight size={20} />,
        title: 'Transfers',
        items:[]
      },
    ]
  },
  {
    title: 'Application',
    items: [
      {
        url: '/app/settings',
        icon: <FiSettings size={20} />,
        title: 'Settings',
        items:[]
      },
    ]
  },
]

export default function navigation(state = initialState, action) {
  switch (action.type) {
    default:
      return state
  }
}
