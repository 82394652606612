import React, { useState, useEffect, useRef } from 'react'
import { useSelector, shallowEqual } from 'react-redux'
// import Portal from '../portal'
import { FiX } from 'react-icons/fi'

const Modal = ({ title, body }) => {
  const { palettes } = useSelector(
    (state) => ({
      palettes: state.palettes
    }),
    shallowEqual
  )
  let { background } = {
    ...palettes
  }

  const modalRef = useRef(null)
  const [open, setOpen] = useState(true)
  const show = () => {
    setOpen(true)
  }
  const hide = () => {
    setOpen(false)
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!modalRef || !modalRef.current) return false
      console.log(modalRef.current.contains(event.target))
      if (!open || modalRef.current.contains(event.target)) {
        return false
      }
      setOpen(!open)
    }
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [open, modalRef])

  return (
    <>
      {/* <button
        className="btn btn-default btn-rounded bg-blue-500 hover:bg-blue-600 text-white"
        type="button"
        onClick={show}>
        Open modal
      </button> */}
      {open && (
        <div selector="#div">
          <div className="modal-backdrop fade-in"></div>
          <div className={`modal show `}>
            <div className="relative min-w-sm w-auto mx-auto" ref={modalRef}>
              <div className="modal-content bg-white">
                <div className="modal-header">
                  <h3 className="text-xl font-semibold">Invest Anytime; Anywhere;</h3>
                  <button
                    className="modal-close btn btn-transparent"
                    onClick={hide}>
                    <FiX size={18} className="stroke-current" />
                  </button>
                </div>
                <div className="relative p-4 flex-auto">
                  <div className="bg-blue-900 my-8  relative" >
                    <div className="p-4">
                      <div className="text-white text-right py-8  lg:hidden">
                        <h1>Invest Anytime; Anywhere</h1>
                        <p className="my-8 text-white">Download our android or ios app and start investing on your mobile today.</p>
                        <div className="flex justify-end">
                          <a className="mx-4" href="https://apps.apple.com/lk/app/optimo-investments/id1579837784#?platform=iphone"><img className="mx-4" src="https://firebasestorage.googleapis.com/v0/b/mf-optimo.appspot.com/o/appstore.png?alt=media&token=a7716442-b79d-490a-8072-8739b01d9959" alt="optimo-app-store" className="w-40" /></a>
                          <a href="https://play.google.com/store/apps/details?id=com.optimoinvest.optimomobile"><img src="https://firebasestorage.googleapis.com/v0/b/mf-optimo.appspot.com/o/playstore.png?alt=media&token=9a543cd7-6b30-4208-8a89-ce15b88673c8" alt="optimo-playstore" className="w-40" /></a>
                        </div>
                      </div>
                      {/* <div className="overflow-y-scroll hide-scrollbar flex flex-col justify-evenly items-start" >
                        <img src="https://firebasestorage.googleapis.com/v0/b/mf-optimo.appspot.com/o/image1-removebg-preview.png?alt=media&token=0a293dc6-c16d-4387-8cf9-a367a651c5f1" />
                      </div> */}
                      <div className="text-white text-right py-16 hidden lg:block ">
                        <h1>Invest Anytime; Anywhere</h1>
                        <p className="my-8 text-white">Download our android or ios app and start investing on your mobile today.</p>
                        <div className="flex justify-end">
                          <a  href="https://apps.apple.com/lk/app/optimo-investments/id1579837784#?platform=iphone"><img className="mx-4" src="https://firebasestorage.googleapis.com/v0/b/mf-optimo.appspot.com/o/appstore.png?alt=media&token=a7716442-b79d-490a-8072-8739b01d9959" alt="optimo-app-store" className="w-40" /></a>
                          <a className="mx-4" href="https://play.google.com/store/apps/details?id=com.optimoinvest.optimomobile"><img src="https://firebasestorage.googleapis.com/v0/b/mf-optimo.appspot.com/o/playstore.png?alt=media&token=9a543cd7-6b30-4208-8a89-ce15b88673c8" alt="optimo-playstore" className="w-40" /></a>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
                {/* <div className="modal-footer space-x-2">
                  <button
                    className="btn btn-default btn-rounded bg-blue-500 hover:bg-blue-600 text-white"
                    type="button"
                    onClick={hide}>
                    Close
                  </button>
                  <button
                    className="btn btn-default btn-rounded bg-blue-500 hover:bg-blue-600 text-white"
                    type="button"
                    onClick={hide}>
                    Save Changes
                  </button>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default Modal
