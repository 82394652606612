import React, { useEffect, useRef, useState } from 'react'

import { useHistory } from 'react-router-dom'
import { RiShieldCheckFill } from 'react-icons/ri'
import { ImBubbles2 } from 'react-icons/im'
import { MdAdd } from 'react-icons/md'

import { FiBriefcase, FiCheck, FiClock, FiPhoneCall, FiShield, FiShoppingCart, FiSmartphone, FiUsers } from 'react-icons/fi'


import { useCollection } from 'react-firebase-hooks/firestore'
import firebase from 'firebase'
import "./style.css";
import { FaBitcoin, FaChartLine, FaChartPie, FaGlobe, FaNewspaper, FaRegLightbulb, FaUsers } from 'react-icons/fa';
import Calculator from '../investing/src/calculator'
import Popup from 'reactjs-popup';
import Modal from '../../components/modals/modal-1'
import EXChart from '../../components/charts/line-1'

const Index = () => {
  const history = useHistory()
  const aboutRef = useRef(null)
  const plansRef = useRef(null)
  const featuresRef = useRef(null)
  const testimoRef = useRef(null)
  const paymentsRef = useRef(null)
  const contactRef = useRef(null)
  const [value, loading, error] = useCollection(firebase.firestore().collection(`packages`), {})
  const [userCount, setCUount] = useState(false)
  const [transactionCount, setTCount] = useState(false)
  const delay = ms => new Promise(res => setTimeout(res, ms));

  const [nlEmail, setNlEmail] = useState(null)

  const counter = async () => {
    while (true) {

      const u_update = Math.floor(Math.random() * 100) + 58;
      var ms = Math.floor(Math.random() * 10000) + 5;
      await delay(ms);
      setCUount(u_update)

      ms = Math.floor(Math.random() * 10000) + 1;
      var ins = Math.floor(Math.random() * 100) + 25;
      var i = transactionCount + ins
      setTCount(i)

      ins = Math.floor(Math.random() * 10);
      await delay(3000);
    }
  }
  useEffect(() => {
    counter()
  }, [])

  return (<div className=" bg-gradient-to-r from-white to-grey-200">

    <Container className="py-8 ">
      <div className="flex justify-between items-center">
        <div className="">
          <img className="h-10" src="https://firebasestorage.googleapis.com/v0/b/mf-optimo.appspot.com/o/logo-wide.png?alt=media&token=553c3ab7-8841-49ae-8477-ba1c907d5c40" alt="optimo-logo-wide" />
        </div>
        <div className="w-24 hidden xl:block"></div>

        <div className="flex justify-end lg:justify-around  flex-grow">
          <LinkButton className="hidden lg:block" to={aboutRef}>Who we are</LinkButton>
          <LinkButton className="hidden lg:block" to={plansRef}>Plans</LinkButton>
          <LinkButton className="hidden lg:block" to={featuresRef}>Features</LinkButton>
          <LinkButton className="hidden lg:block" to={testimoRef}>Testimonials</LinkButton>
          <LinkButton className="hidden lg:block" to={paymentsRef}>Payment Methods</LinkButton>
          <LinkButton className="hidden lg:block" to={contactRef}>Contact Us</LinkButton>
          <div className="grid grid-cols-2 gap-2 place-items-center">
            <ButtonPrimary onClick={() => window.location.href = `https://my.optimoinvest.com/create-account`}>Sign Up</ButtonPrimary>
            <ButtonOutline onClick={() => window.location.href = `https://my.optimoinvest.com/login`}>Login</ButtonOutline>
          </div>
        </div>
      </div>
    </Container>

    {/* Hero */}
    <Container className="py-8 " >
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 place-items-center">
        <div className="py-16 px-8 lg:px-0">
          <h6 >Often Have Small</h6>
          <h1 >Invest Your Money  <br /> For Future</h1>
          <p className="my-4 text-justify">“While saving involves putting some of today's money away for tomorrow, investment includes bringing your money to invest in order to appropriately generate a greater return over time. ”</p>
          <div className="flex  items-center">
            <ButtonPrimary onClick={() => window.location.href = `https://my.optimoinvest.com/login`}>Start Now</ButtonPrimary>
            <LinkButton to={plansRef}>
              <ButtonOutline >View Plans</ButtonOutline>
            </LinkButton>
          </div>
        </div>
        <div >
          <img alt="" src="https://firebasestorage.googleapis.com/v0/b/mf-optimo.appspot.com/o/Group%201041.png?alt=media&token=9a8c41bb-a191-4d30-b7e6-950ae9f7db49" />
        </div>
      </div>
    </Container>

    {/* About */}
    <Container>
      <div className="md:text-center" ref={aboutRef}>
        <h6 className="">Who are we</h6>
        <h2 >Welcome To Optimo Investments</h2>
        <p className="mt-4 max-w-3xl mx-auto">Put your investing ideas into action with full range of investments. Enjoy real benefits and rewards on your accrue investing.</p>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 my-16 gap-4 max-w-8xl mx-auto place-content-center place-items-center">

        <div className="rounded-lg p-8 w-80 h-96 text-center bg-white shadow-xl  transition duration-200 ease-in-out hover:-translate-y-2 transform cursor-pointer">
          <div className="yellow-box mx-auto flex items-center justify-center w-32 h-32">
            <FaUsers size="64" color="white" />
          </div>
          <h4 className="mt-4">Traders</h4>
          <p>Providing best customer service is our primary value and always keeping touch with them.</p>
        </div>
        <div className="rounded-lg p-8 w-80 h-96 text-center bg-white shadow-xl  transition duration-200 ease-in-out hover:-translate-y-2 transform cursor-pointer">
          <div className="blue-box mx-auto flex items-center justify-center w-32 h-32">
            <RiShieldCheckFill size="64" color="white" />
          </div>
          <h4 className="mt-4">Reliability</h4>
          <p>Since being a trustworthy merchant in digital flat form, We go above and beyond to meet the demands of our clientele.</p>
        </div>
        <div className="rounded-lg p-8 w-80 h-96 text-center bg-white shadow-xl  transition duration-200 ease-in-out hover:-translate-y-2 transform cursor-pointer">
          <div className="green-box mx-auto flex items-center justify-center w-32 h-32">
            <FiSmartphone size="64" color="white" />
          </div>
          <h4 className="mt-4">Simplicity</h4>
          <p>With our simple trading platform, anyone can become a trader. Optimo Investment is accessible on all relevant platforms, including the following: Web, Windows, MacOS, iPhone, iPad and Android.</p>
        </div>
        <div className="rounded-lg p-8 w-80 h-96 text-center bg-white shadow-xl  transition duration-200 ease-in-out hover:-translate-y-2 transform cursor-pointer">
          <div className="yellow-box mx-auto flex items-center justify-center w-32 h-32">
            <FiClock size="64" color="white" />
          </div>
          <h4 className="mt-4">Speed</h4>
          <p>We provide fastest trading using cutting-edge technologies. No delays in order executions and lags in user interface.</p>
        </div>
      </div>
      <div className="flex justify-center my-8">
        <ButtonPrimary onClick={() => window.location.href = `https://my.optimoinvest.com`}>Get More</ButtonPrimary>
      </div>
    </Container>

    {/* Steps */}
    <div ref={plansRef} className="relative my-8 mb-32"  >
      <div className="text-center">
        <h6 className="text-lg">Easy Investing</h6>
        <h2 className="font-bold text-4xl">3 Simple Steps</h2>
      </div>
      <div className="absolute w-screen left-0 -bottom-16 h-72 bg-blue-900">
      </div>
      <Container>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 mt-8 gap-4 ">
          <div className=" flex items-center justify-start rounded-lg p-8 bg-white shadow-2xl transition duration-200 ease-in-out hover:-translate-y-2 transform cursor-pointer">
            <div className="blue-box-2 flex items-center justify-center w-16 h-16">
              <FaNewspaper size="32" color="white" />
            </div>
            <h4 className="text-xl font-bold ml-4">Select Your Package</h4>
          </div>
          <div className=" flex items-center justify-start rounded-lg p-8 bg-white shadow-2xl transition duration-200 ease-in-out hover:-translate-y-2 transform cursor-pointer">
            <div className="blue-box-2 flex items-center justify-center w-16 h-16">
              <RiShieldCheckFill size="32" color="white" />
            </div>
            <h4 className="text-xl font-bold ml-4">Invest Your Money</h4>
          </div>
          <div className=" flex items-center justify-start rounded-lg p-8 bg-white shadow-2xl transition duration-200 ease-in-out hover:-translate-y-2 transform cursor-pointer">
            <div className="blue-box-2 flex items-center justify-center w-16 h-16">
              <FaChartLine size="32" color="white" />
            </div>
            <h4 className="text-xl font-bold ml-4">Earn Bonuses</h4>
          </div>
        </div>
      </Container>
    </div>
    <Modal />

    {/* Packages */}
    <div ref={plansRef} className="relative my-8 mb-32"  >
      <div className="text-center">
        <h6 className="text-lg">Our Plans</h6>
        <h2 className="font-bold text-4xl">Our Investment Plans</h2>
        <p>The capital amount can be withdraw based on the package you selected.</p>
      </div>
      <div className="absolute w-screen left-0 -bottom-16 h-72 bg-blue-900">
      </div>
      <Container>
        {error && ''}
        {loading && ''}
        {value && <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 my-8  gap-4">
          {value.docs.map((doc) => (
            <div className=" rounded-lg p-8  text-left bg-white shadow-xl  transition duration-200 ease-in-out hover:-translate-y-2 transform cursor-pointer">
              <h4 className="py-2 px-8" ><span className="text-blue-900 mr-2">{doc.data()['name']}</span></h4>
              <p className="border-b border-grey-200 py-2 px-8">{doc.data()['min_invest']} USD Minimum Investment</p>
              <p className="border-b border-grey-200 py-2 px-8">{doc.data()['duration']} Months</p>
              <p className="border-b border-grey-200 py-2 px-8">{doc.data()['interest']}% of Interest</p>
              <p className="border-grey-200 py-2 px-8">10 % refferal Bonus</p>
              <p className="border-grey-200 py-2 px-8">Enjoy your interest every Month</p>
              <p className="border-grey-200 py-2 px-8">{(parseFloat(doc.data()['min_invest']) / 100 * parseFloat(doc.data()['interest'])).toFixed(2)} USD Bonus</p>
              <div className="flex justify-center mt-4">
                <ButtonPrimary onClick={() => window.location.href = `https://my.optimoinvest.com`} className="w-32">Invest Now</ButtonPrimary>
              </div>
            </div>
          ))}
        </div>}
        <div>
          <Calculator />
        </div>
      </Container>
    </div >

    {/* <Container >
      <div className="text-center">
        <h2 className="font-bold text-4xl">Latest Transactions</h2>
      </div>
      <div className="mt-8 p-8 bg-gray-200 rounded-md overflow-x-scroll hide-scrollbar">
        <table className=" min-w-full text-primary-500 text-center">
          <tr>
            <th className="text-left p-2">Name</th>
            <th>Date</th>
            <th>Amount</th>
            <th>Currency</th>
            <th>Deposit</th>
          </tr>
          <div className="h-2"></div>
          <tr>
            <td className="py-2 rounded-l-md bg-white flex items-center">
              <img className="mx-2 w-10 h-10 rounded" src="https://images.unsplash.com/photo-1551434678-e076c223a692?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8bWFsZSUyMG9mZmljZXxlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60" alt="" />
              Olympia Ripple</td>
            <td className="py-2 bg-white">June 24, 2019</td>
            <td className="py-2 bg-white">$16,00,000.00</td>
            <td className="py-2 bg-white">
              <h5 className=" bg-purple-100 text-purple-500 rounded-md px-2 py-1">Dollar</h5>
            </td>
            <td className="py-2 rounded-r-md bg-white">03 Minutes Ago</td>
          </tr>
          <div className="h-2"></div>
          <tr>
            <td className="py-2 rounded-l-md bg-white flex items-center">
              <img className="mx-2 w-10 h-10 rounded" src="https://images.unsplash.com/photo-1551434678-e076c223a692?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8bWFsZSUyMG9mZmljZXxlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60" alt="" />
              Olympia Ripple</td>
            <td className="py-2 bg-white">June 24, 2019</td>
            <td className="py-2 bg-white">$16,00,000.00</td>
            <td className="py-2 bg-white">     <h5 className=" bg-green-100 text-green-500 rounded-md px-2 py-1">Card</h5></td>
            <td className="py-2 rounded-r-md bg-white">03 Minutes Ago</td>
          </tr>
          <div className="h-2"></div>
          <tr>
            <td className="py-2 rounded-l-md bg-white flex items-center">
              <img className="mx-2 w-10 h-10 rounded" src="https://images.unsplash.com/photo-1551434678-e076c223a692?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8bWFsZSUyMG9mZmljZXxlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60" alt="" />
              Olympia Ripple</td>
            <td className="py-2 bg-white">June 24, 2019</td>
            <td className="py-2 bg-white">$16,00,000.00</td>
            <td className="py-2 bg-white">     <h5 className=" bg-yellow-100 text-yellow-500 rounded-md px-2 py-1">Bitcoin</h5></td>
            <td className="py-2 rounded-r-md bg-white">03 Minutes Ago</td>
          </tr>
          <div className="h-2"></div>
          <tr>
            <td className="py-2 rounded-l-md bg-white flex items-center">
              <img className="mx-2 w-10 h-10 rounded" src="https://images.unsplash.com/photo-1551434678-e076c223a692?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8bWFsZSUyMG9mZmljZXxlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60" alt="" />
              Olympia Ripple</td>
            <td className="py-2 bg-white">June 24, 2019</td>
            <td className="py-2 bg-white">$16,00,000.00</td>
            <td className="py-2 bg-white">     <h5 className=" bg-red-100 text-red-500 rounded-md px-2 py-1">Bitcoin</h5></td>
            <td className="py-2 rounded-r-md bg-white">03 Minutes Ago</td>
          </tr>
          <div className="h-2"></div>
          <tr>
            <td className="py-2 rounded-l-md bg-white flex items-center">
              <img className="mx-2 w-10 h-10 rounded" src="https://images.unsplash.com/photo-1551434678-e076c223a692?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8bWFsZSUyMG9mZmljZXxlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60" alt="" />
              Olympia Ripple</td>
            <td className="py-2 bg-white">June 24, 2019</td>
            <td className="py-2 bg-white">$16,00,000.00</td>
            <td className="py-2 bg-white">     <h5 className=" bg-blue-100 text-blue-500 rounded-md px-2 py-1">Bitcoin</h5></td>
            <td className="py-2 rounded-r-md bg-white">03 Minutes Ago</td>
          </tr>
        </table>
      </div>
    </Container> */}


    {/* Features */}
    <Container>
      <div className="text-center" ref={featuresRef} >
        <h6 >Services</h6>
        <h2 >Our Features</h2>
        <p className="max-w-3xl mx-auto mb-8">At Optimo Investment, we believe in assisting people to achieve their long-term investment ambitions. We are dedicated to helping you to achieve your trading and investor strategy, if you are new to trading or a senior expert, with a comprehensive range of strong trading technology, online brokerage services, and trading education.
          In here we are providing opportunity to join our future investment plan</p>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 mt-8 gap-4">
        <div className=" flex items-center justify-start rounded-lg p-8 bg-white shadow-2xl transition duration-200 ease-in-out hover:-translate-y-2 transform cursor-pointer">
          <div className="pink-box flex items-center justify-center w-16 h-16">
            <FaNewspaper size="32" color="white" />
          </div>
          <h4 className="text-xl font-bold ml-4">We're Certified</h4>
          {/* <p className="mt-2 mb-8">Lorem ipsum dolor sit amet, consectetur
            adipiscing elit, sed do Ut enim ad minim veniam
            Quis nostrud exercitation cillum.</p> */}
          {/* <ButtonPrimary onClick={() => window.location.href = `https://my.optimoinvest.com`}>Read More</ButtonPrimary> */}
        </div>

        <div className=" flex items-center justify-start rounded-lg p-8 bg-white shadow-2xl transition duration-200 ease-in-out hover:-translate-y-2 transform cursor-pointer">
          <div className="blue-box-2 flex items-center justify-center w-16 h-16">
            <RiShieldCheckFill size="32" color="white" />
          </div>
          <h4 className="text-xl font-bold ml-4">We're Secure</h4>
          {/* <p className="mt-2 mb-8">Lorem ipsum dolor sit amet, consectetur
            adipiscing elit, sed do Ut enim ad minim veniam
            Quis nostrud exercitation cillum.</p> */}
          {/* <ButtonPrimary onClick={() => window.location.href = `https://my.optimoinvest.com`}>Read More</ButtonPrimary> */}
        </div>

        <div className=" flex items-center justify-start rounded-lg p-8 bg-white shadow-2xl transition duration-200 ease-in-out hover:-translate-y-2 transform cursor-pointer">
          <div className="green-box-2  flex items-center justify-center w-16 h-16 ">
            <FaChartLine size="32" color="white" />
          </div>
          <h4 className="text-xl font-bold ml-4">We're Profitable</h4>
          {/* <p className="mt-2 mb-8">Lorem ipsum dolor sit amet, consectetur
            adipiscing elit, sed do Ut enim ad minim veniam
            Quis nostrud exercitation cillum.</p> */}
          {/* <ButtonPrimary onClick={() => window.location.href = `https://my.optimoinvest.com`}>Read More</ButtonPrimary> */}
        </div>

        <div className=" flex items-center justify-start rounded-lg p-8 bg-white shadow-2xl transition duration-200 ease-in-out hover:-translate-y-2 transform cursor-pointer">
          <div className="orange-box flex items-center justify-center w-16 h-16">
            <FaBitcoin size="32" color="white" />
          </div>
          <h4 className="text-xl font-bold ml-4">We Accept Crypto</h4>
          {/* <p className="mt-2 mb-8">Lorem ipsum dolor sit amet, consectetur
            adipiscing elit, sed do Ut enim ad minim veniam
            Quis nostrud exercitation cillum.</p> */}
          {/* <ButtonPrimary onClick={() => window.location.href = `https://my.optimoinvest.com`}>Read More</ButtonPrimary> */}
        </div>

        <div className=" flex items-center justify-start rounded-lg p-8 bg-white shadow-2xl transition duration-200 ease-in-out hover:-translate-y-2 transform cursor-pointer">
          <div className="blue-box-2 flex items-center justify-center w-16 h-16">
            <ImBubbles2 size="32" color="white" />
          </div>
          <h4 className="text-xl font-bold ml-4">Best Support</h4>
          {/* <p className="mt-2 mb-8">Lorem ipsum dolor sit amet, consectetur
            adipiscing elit, sed do Ut enim ad minim veniam
            Quis nostrud exercitation cillum.</p> */}
          {/* <ButtonPrimary onClick={() => window.location.href = `https://my.optimoinvest.com`}>Read More</ButtonPrimary> */}
        </div>
        <div className=" flex items-center justify-start rounded-lg p-8 bg-white shadow-2xl transition duration-200 ease-in-out hover:-translate-y-2 transform cursor-pointer">
          <div className="red-box flex items-center justify-center w-16 h-16">
            <FaGlobe size="32" color="white" />
          </div>
          <h4 className="text-xl font-bold ml-4">We're Global</h4>
          {/* <p className="mt-2 mb-8">Lorem ipsum dolor sit amet, consectetur
            adipiscing elit, sed do Ut enim ad minim veniam
            Quis nostrud exercitation cillum.</p> */}
          {/* <ButtonPrimary onClick={() => window.location.href = `https://my.optimoinvest.com`}>Read More</ButtonPrimary> */}
        </div>

      </div>
    </Container>

    {/* <Container ref={featuresRef} className="py-8 lg:py-16 h-screen">
      <div className=" bg-gray-300 h-full w-full text-gray-700  flex items-center justify-center">
        <h2>trading live chart</h2>
      </div>
    </Container> */}

    {/* Stats */}
    <Container className="my-16">
      <div className="text-center">
        <h6 >Statistics</h6>
        <h2>What's Happening Now</h2>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 max-w-xl mx-auto mt-8">
        <div className="bg-white flex flex-col items-center justify-center w-full sm:w-64 px-4 py-12 rounded-md  shadow-xl transition duration-200 ease-in-out hover:translate-y-2  transform cursor-pointer">
          <p>Active Users</p>
          <h5 className="text-4xl font-bold ">{userCount}</h5>
        </div>
        <div className="bg-white flex flex-col items-center justify-center w-full sm:w-64 px-4 py-12 rounded-md  shadow-xl transition duration-200 ease-in-out hover:translate-y-2  transform cursor-pointer">
          <p>Current Transactions</p>
          <h5 className="text-4xl font-bold">{transactionCount}</h5>
        </div>
      </div>
      <div className="text-center mt-8">
        <h2>Daily User Activities</h2>
      </div>
      <EXChart/>
    </Container>

    {/* Clients */}
    <div className="bg-blue-900 my-8  relative" >
      <svg width="417" height="417" viewBox="0 0 417 417" fill="none" xmlns="http://www.w3.org/2000/svg" className="absolute -top-32 -right-32">
        <g opacity="0.1">
          <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="417" height="417">
            <circle cx="208.824" cy="208.202" r="208" fill="white" />
          </mask>
          <g mask="url(#mask0)">
            <circle cx="7.8398" cy="9.31886" r="9.11671" fill="white" />
            <circle cx="7.83955" cy="65.8423" r="9.11671" fill="white" />
            <circle cx="7.83955" cy="122.365" r="9.11671" fill="white" />
            <circle cx="7.83955" cy="178.889" r="9.11671" fill="white" />
            <circle cx="7.83955" cy="235.413" r="9.11671" fill="white" />
            <circle cx="7.83955" cy="291.936" r="9.11671" fill="white" />
            <circle cx="7.83955" cy="348.46" r="9.11671" fill="white" />
            <circle cx="7.83955" cy="404.984" r="9.11671" fill="white" />
            <circle cx="58.8931" cy="9.31837" r="9.11671" fill="white" />
            <circle cx="58.8931" cy="65.8423" r="9.11671" fill="white" />
            <circle cx="58.8931" cy="122.365" r="9.11671" fill="white" />
            <circle cx="58.8931" cy="178.889" r="9.11671" fill="white" />
            <circle cx="58.8931" cy="235.413" r="9.11671" fill="white" />
            <circle cx="58.8931" cy="291.936" r="9.11671" fill="white" />
            <circle cx="58.8931" cy="348.46" r="9.11671" fill="white" />
            <circle cx="58.8931" cy="404.984" r="9.11671" fill="white" />
            <circle cx="109.947" cy="9.31837" r="9.11671" fill="white" />
            <circle cx="109.947" cy="65.8423" r="9.11671" fill="white" />
            <circle cx="109.947" cy="122.365" r="9.11671" fill="white" />
            <circle cx="109.947" cy="178.889" r="9.11671" fill="white" />
            <circle cx="109.947" cy="235.413" r="9.11671" fill="white" />
            <circle cx="109.947" cy="291.936" r="9.11671" fill="white" />
            <circle cx="109.947" cy="348.46" r="9.11671" fill="white" />
            <circle cx="109.947" cy="404.984" r="9.11671" fill="white" />
            <circle cx="161.001" cy="9.31837" r="9.11671" fill="white" />
            <circle cx="161.001" cy="65.8423" r="9.11671" fill="white" />
            <circle cx="161.001" cy="122.365" r="9.11671" fill="white" />
            <circle cx="161.001" cy="178.889" r="9.11671" fill="white" />
            <circle cx="161.001" cy="235.413" r="9.11671" fill="white" />
            <circle cx="161.001" cy="291.936" r="9.11671" fill="white" />
            <circle cx="161.001" cy="348.46" r="9.11671" fill="white" />
            <circle cx="161.001" cy="404.984" r="9.11671" fill="white" />
            <circle cx="212.054" cy="9.31837" r="9.11671" fill="white" />
            <circle cx="212.054" cy="65.8423" r="9.11671" fill="white" />
            <circle cx="212.054" cy="122.365" r="9.11671" fill="white" />
            <circle cx="212.054" cy="178.889" r="9.11671" fill="white" />
            <circle cx="212.054" cy="235.413" r="9.11671" fill="white" />
            <circle cx="212.054" cy="291.936" r="9.11671" fill="white" />
            <circle cx="212.054" cy="348.46" r="9.11671" fill="white" />
            <circle cx="212.054" cy="404.984" r="9.11671" fill="white" />
            <circle cx="263.108" cy="9.31837" r="9.11671" fill="white" />
            <circle cx="263.108" cy="65.8423" r="9.11671" fill="white" />
            <circle cx="263.108" cy="122.365" r="9.11671" fill="white" />
            <circle cx="263.108" cy="178.889" r="9.11671" fill="white" />
            <circle cx="263.108" cy="235.413" r="9.11671" fill="white" />
            <circle cx="263.108" cy="291.936" r="9.11671" fill="white" />
            <circle cx="263.108" cy="348.46" r="9.11671" fill="white" />
            <circle cx="263.108" cy="404.984" r="9.11671" fill="white" />
            <circle cx="314.161" cy="9.31837" r="9.11671" fill="white" />
            <circle cx="314.161" cy="65.8423" r="9.11671" fill="white" />
            <circle cx="314.161" cy="122.365" r="9.11671" fill="white" />
            <circle cx="314.161" cy="178.889" r="9.11671" fill="white" />
            <circle cx="314.161" cy="235.413" r="9.11671" fill="white" />
            <circle cx="314.161" cy="291.936" r="9.11671" fill="white" />
            <circle cx="314.161" cy="348.46" r="9.11671" fill="white" />
            <circle cx="314.161" cy="404.984" r="9.11671" fill="white" />
            <circle cx="365.215" cy="9.31837" r="9.11671" fill="white" />
            <circle cx="365.215" cy="65.8423" r="9.11671" fill="white" />
            <circle cx="365.215" cy="122.365" r="9.11671" fill="white" />
            <circle cx="365.215" cy="178.889" r="9.11671" fill="white" />
            <circle cx="365.215" cy="235.413" r="9.11671" fill="white" />
            <circle cx="365.215" cy="291.936" r="9.11671" fill="white" />
            <circle cx="365.215" cy="348.46" r="9.11671" fill="white" />
            <circle cx="365.215" cy="404.984" r="9.11671" fill="white" />
            <circle cx="416.268" cy="9.31837" r="9.11671" fill="white" />
            <circle cx="416.268" cy="65.8423" r="9.11671" fill="white" />
            <circle cx="416.268" cy="122.365" r="9.11671" fill="white" />
            <circle cx="416.268" cy="178.889" r="9.11671" fill="white" />
            <circle cx="416.268" cy="235.413" r="9.11671" fill="white" />
            <circle cx="416.268" cy="291.936" r="9.11671" fill="white" />
            <circle cx="416.268" cy="348.46" r="9.11671" fill="white" />
            <circle cx="416.268" cy="404.984" r="9.11671" fill="white" />
          </g>
        </g>
      </svg>

      <Container className="grid grid-cols-1 lg:grid-cols-2 gap-4 place-items-center">
        <div className="text-white text-right py-32  lg:hidden">
          <h1>What Our Clients Say</h1>
          <p className="my-8 text-white">Put your investing ideas into action with full range of investments. Duis auteir ure dolor in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Enjoy real benefits and rewards on your accrue investing.</p>
          <button className="text-center px-4 w-full py-1  h-10  border-white border  text-white  rounded-md transition duration-200 ease-in-out hover:-translate-y-1 transform">Start your joruney today!</button>
        </div>
        <div className="h-screen overflow-y-scroll hide-scrollbar flex flex-col justify-evenly items-start" ref={testimoRef} >
          <div className="rounded-lg p-4 bg-white flex items-center justify-items-start cursor-pointer mb-4">
            <img src="https://images.unsplash.com/photo-1590649880765-91b1956b8276?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80" alt="" className="h-24 w-24 rounded-md object-cover" />
            <div className="ml-4 max-w-sm">
              <h4>Liberty Berger <span className="ml-2 text-purple-500 text-sm"></span></h4>
              <p className="mt-2">“Hello, I have done so many investment effort in my life.unfortunately any thing was not success.finally I have engage with Optimo Investment and it’s the best investment app I have ever used.”</p>
            </div>
          </div>
          <div className="rounded-lg p-4 bg-white flex items-center justify-items-start cursor-pointer mb-4">
            <img src="https://images.unsplash.com/photo-1610389051254-64849803c8fd?ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8bWFsZSUyMG9mZmljZXxlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60" alt="" className="h-24 w-24 rounded-md object-cover" />
            <div className="ml-4 max-w-sm">
              <h4>Casian Vedere <span className="ml-2 text-purple-500 text-sm"></span></h4>
              <p className="mt-2">“Hi,
                Wow. This is amazing.I have go through plenty of fake apps,sites and scams.But this
                is perfect and trustworthy.Highly recommended.”</p>
            </div>
          </div>
          <div className="rounded-lg p-4 bg-white flex items-center justify-items-start cursor-pointer mb-4">
            <img src="https://images.unsplash.com/photo-1600275669439-14e40452d20b?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MjB8fGZlbWFsZSUyMG9mZmljZXxlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60" alt="" className="h-24 w-24 rounded-md object-cover" />
            <div className="ml-4 max-w-sm">
              <h4>Zara Dikwani <span className="ml-2 text-purple-500 text-sm"></span></h4>
              <p className="mt-2">“Hello
                I’m Zara Dikwani, living in Mumbai, India. Every day we struggling with economy and
                competition.there is no any trustworthy investment plan anywhere.But this Optimo
                Investment is helped me to find reality of the investment.”</p>
            </div>
          </div>
          <div className="rounded-lg p-4 bg-white flex items-center justify-items-start cursor-pointer mb-4">
            <img src="https://images.unsplash.com/photo-1507296950187-9b0c7c88e7af?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1yZWxhdGVkfDN8fHxlbnwwfHx8fA%3D%3D&auto=format&fit=crop&w=500&q=60" alt="" className="h-24 w-24 rounded-md object-cover" />
            <div className="ml-4 max-w-sm">
              <h4>Bianka Ashton <span className="ml-2 text-purple-500 text-sm"></span></h4>
              <p className="mt-2">“I've worked with numerous investing apps in my life, but Optimo was a greatest app
                to invest in. I started my financial experience using this app, and it assisted me in
                determining where I should invest. Optimo ethics are impeccable.
                I recommend that you join them.”</p>
            </div>
          </div>
          <div className="rounded-lg p-4 bg-white flex items-center justify-items-start cursor-pointer mb-4">
            <img src="https://images.unsplash.com/photo-1590649880765-91b1956b8276?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80" alt="" className="h-24 w-24 rounded-md object-cover" />
            <div className="ml-4 max-w-sm">
              <h4>Liberty Berger <span className="ml-2 text-purple-500 text-sm"></span></h4>
              <p className="mt-2">“Optimo Investment is a great investment app. I had the pleasure of investing with
                them for two years, and Optimo has always retained my trust, and I feel this app is
                the greatest thing ever.”</p>
            </div>
          </div>
          <div className="rounded-lg p-4 bg-white flex items-center justify-items-start cursor-pointer mb-4">
            <img src="https://images.unsplash.com/photo-1611432579402-7037e3e2c1e4?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=701&q=80" alt="" className="h-24 w-24 rounded-md object-cover" />
            <div className="ml-4 max-w-sm">
              <h4>Tahir Merrill <span className="ml-2 text-purple-500 text-sm"></span></h4>
              <p className="mt-2">“If you are seeking for a genuine investment opportunity, Optimo Investment is the
                place to really go. Superb wealthy plany”</p>
            </div>
          </div>
          <div className="rounded-lg p-4 bg-white flex items-center justify-items-start cursor-pointer mb-4">
            <img src="https://images.unsplash.com/photo-1610389051254-64849803c8fd?ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8bWFsZSUyMG9mZmljZXxlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60" alt="" className="h-24 w-24 rounded-md object-cover" />
            <div className="ml-4 max-w-sm">
              <h4>Leah V. Castro <span className="ml-2 text-purple-500 text-sm"></span></h4>
              <p className="mt-2">“I've been working with Optimo for a year and they've always maintained my trust
                and security. I'd never seen these things before. Optimo is knowledgeable, articulate, and a trust to”</p>
            </div>
          </div>

        </div>
        <div className="text-white text-right py-32 hidden lg:block ">
          <h1>What Our Clients Say</h1>
          <p className="my-8 text-white">Put your investing ideas into action with full range of investments. Enjoy real benefits and rewards on your accrue investing.</p>
          <button onClick={() => window.location.href = `https://my.optimoinvest.com/login`} className=" px-4 w-64 py-1  h-10  border-white border  text-white  rounded-md transition duration-200 ease-in-out hover:-translate-y-1 transform">Start your journey today!</button>
        </div>

      </Container>
    </div>


    {/* Payment Methods */}
    <Container >
      <div className="text-center" ref={paymentsRef}>
        <h6 className="text-blue-900 text-lg">Payment Methods</h6>
        <h2 className="font-bold text-4xl">Accepted Payment Methods</h2>
      </div>
      <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 my-8 max-w-3xl mx-auto gap-4">
        <div className=" rounded-lg p-8 bg-white shadow-xl flex items-center justify-center transition duration-200 ease-in-out hover:-translate-y-2 transform cursor-pointer">
          <img src="https://firebasestorage.googleapis.com/v0/b/mf-optimo.appspot.com/o/Bitcoin-Logo.wine.png?alt=media&token=ffbdbe09-0caa-474c-af01-2a1e598d1896" alt="btc" className="h-16" />
        </div>
        <div className=" rounded-lg p-8 bg-white shadow-xl flex items-center justify-center transition duration-200 ease-in-out hover:-translate-y-2 transform cursor-pointer">
          <img src="https://firebasestorage.googleapis.com/v0/b/mf-optimo.appspot.com/o/tether-usdt-logo.png?alt=media&token=aecbda16-142c-4407-a10e-1eeb21053621" alt="btc" className="h-16" />
        </div>

        <div className=" rounded-lg p-8 bg-white shadow-xl flex items-center justify-center transition duration-200 ease-in-out hover:-translate-y-2 transform cursor-pointer">
          <img src="https://firebasestorage.googleapis.com/v0/b/mf-optimo.appspot.com/o/58482363cef1014c0b5e49c1.png?alt=media&token=fb1acb84-bf01-4b10-9c4e-47c92032314d" alt="visa" className="h-12" />
        </div>
        <div className=" rounded-lg p-8 bg-white shadow-xl flex items-center justify-center transition duration-200 ease-in-out hover:-translate-y-2 transform cursor-pointer">
          <img src="https://firebasestorage.googleapis.com/v0/b/mf-optimo.appspot.com/o/Maestro-logo.png?alt=media&token=618f9059-2a74-47e6-a407-9401c8f22435" alt="maestro" className="h-12" />
        </div>
        <div className=" rounded-lg p-8 bg-white shadow-xl flex items-center justify-center transition duration-200 ease-in-out hover:-translate-y-2 transform cursor-pointer">
          <img src="https://firebasestorage.googleapis.com/v0/b/mf-optimo.appspot.com/o/MasterCard_early_1990s_logo.svg.png?alt=media&token=961ce757-5d7d-4e37-9337-615a82dd22d2" alt="master card" className="h-12" />
        </div>
        <div className=" rounded-lg p-8 bg-white shadow-xl flex items-center justify-center transition duration-200 ease-in-out hover:-translate-y-2 transform cursor-pointer">
          <img src="https://firebasestorage.googleapis.com/v0/b/mf-optimo.appspot.com/o/american-express-logo-png-81-images-amex-logo-png-1920_1059.png?alt=media&token=dbef2e6e-f6bd-4dcb-92c9-f55b0cf3abb3" alt="american express" className="h-16" />
        </div>
      </div>
    </Container>

    {/* FAQ Section */}
    <Container className="grid grid-cols-1 lg:grid-cols-2 gap-4 place-items-center">
      <div className=" text-left py-32">
        <h6>FAQ Section</h6>
        <h2>How Can We Help You?</h2>
        <p className="lg:my-8 max-w-lg ">Our core values are at the heart of all that we do. They are integrated
          into our daily work lives and help us to remember our customers
          always comes first, the last thank you should always come from us.</p>
      </div>
      <div className="w-full">
        <div className="w-full p-4 rounded-md transition duration-200 ease-in-out hover:translate-x-2 transform cursor-pointer mb-2">
          <h5 className="text-blue-900">Lorem Ipsum?</h5>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do Ut enim ad minim veniam Quis nostrud exercitation cillum.</p>
        </div>
        <div className="w-full p-4 rounded-md transition duration-200 ease-in-out hover:translate-x-2 transform cursor-pointer mb-2">
          <h5 className="text-blue-900">Lorem Ipsum?</h5>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do Ut enim ad minim veniam Quis nostrud exercitation cillum.</p>
        </div>
        <div className="w-full p-4 rounded-md transition duration-200 ease-in-out hover:translate-x-2 transform cursor-pointer mb-2">
          <h5 className="text-blue-900">Lorem Ipsum?</h5>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do Ut enim ad minim veniam Quis nostrud exercitation cillum.</p>
        </div>
        <div className="w-full p-4 rounded-md transition duration-200 ease-in-out hover:translate-x-2 transform cursor-pointer mb-2">
          <h5 className="text-blue-900">Lorem Ipsum?</h5>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do Ut enim ad minim veniam Quis nostrud exercitation cillum.</p>
        </div>
      </div>
    </Container>

    <div className="bg-blue-900 my-8  relative" >
      <svg width="417" height="417" viewBox="0 0 417 417" fill="none" xmlns="http://www.w3.org/2000/svg" className="absolute -top-32 -right-32">
        <g opacity="0.1">
          <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="417" height="417">
            <circle cx="208.824" cy="208.202" r="208" fill="white" />
          </mask>
          <g mask="url(#mask0)">
            <circle cx="7.8398" cy="9.31886" r="9.11671" fill="white" />
            <circle cx="7.83955" cy="65.8423" r="9.11671" fill="white" />
            <circle cx="7.83955" cy="122.365" r="9.11671" fill="white" />
            <circle cx="7.83955" cy="178.889" r="9.11671" fill="white" />
            <circle cx="7.83955" cy="235.413" r="9.11671" fill="white" />
            <circle cx="7.83955" cy="291.936" r="9.11671" fill="white" />
            <circle cx="7.83955" cy="348.46" r="9.11671" fill="white" />
            <circle cx="7.83955" cy="404.984" r="9.11671" fill="white" />
            <circle cx="58.8931" cy="9.31837" r="9.11671" fill="white" />
            <circle cx="58.8931" cy="65.8423" r="9.11671" fill="white" />
            <circle cx="58.8931" cy="122.365" r="9.11671" fill="white" />
            <circle cx="58.8931" cy="178.889" r="9.11671" fill="white" />
            <circle cx="58.8931" cy="235.413" r="9.11671" fill="white" />
            <circle cx="58.8931" cy="291.936" r="9.11671" fill="white" />
            <circle cx="58.8931" cy="348.46" r="9.11671" fill="white" />
            <circle cx="58.8931" cy="404.984" r="9.11671" fill="white" />
            <circle cx="109.947" cy="9.31837" r="9.11671" fill="white" />
            <circle cx="109.947" cy="65.8423" r="9.11671" fill="white" />
            <circle cx="109.947" cy="122.365" r="9.11671" fill="white" />
            <circle cx="109.947" cy="178.889" r="9.11671" fill="white" />
            <circle cx="109.947" cy="235.413" r="9.11671" fill="white" />
            <circle cx="109.947" cy="291.936" r="9.11671" fill="white" />
            <circle cx="109.947" cy="348.46" r="9.11671" fill="white" />
            <circle cx="109.947" cy="404.984" r="9.11671" fill="white" />
            <circle cx="161.001" cy="9.31837" r="9.11671" fill="white" />
            <circle cx="161.001" cy="65.8423" r="9.11671" fill="white" />
            <circle cx="161.001" cy="122.365" r="9.11671" fill="white" />
            <circle cx="161.001" cy="178.889" r="9.11671" fill="white" />
            <circle cx="161.001" cy="235.413" r="9.11671" fill="white" />
            <circle cx="161.001" cy="291.936" r="9.11671" fill="white" />
            <circle cx="161.001" cy="348.46" r="9.11671" fill="white" />
            <circle cx="161.001" cy="404.984" r="9.11671" fill="white" />
            <circle cx="212.054" cy="9.31837" r="9.11671" fill="white" />
            <circle cx="212.054" cy="65.8423" r="9.11671" fill="white" />
            <circle cx="212.054" cy="122.365" r="9.11671" fill="white" />
            <circle cx="212.054" cy="178.889" r="9.11671" fill="white" />
            <circle cx="212.054" cy="235.413" r="9.11671" fill="white" />
            <circle cx="212.054" cy="291.936" r="9.11671" fill="white" />
            <circle cx="212.054" cy="348.46" r="9.11671" fill="white" />
            <circle cx="212.054" cy="404.984" r="9.11671" fill="white" />
            <circle cx="263.108" cy="9.31837" r="9.11671" fill="white" />
            <circle cx="263.108" cy="65.8423" r="9.11671" fill="white" />
            <circle cx="263.108" cy="122.365" r="9.11671" fill="white" />
            <circle cx="263.108" cy="178.889" r="9.11671" fill="white" />
            <circle cx="263.108" cy="235.413" r="9.11671" fill="white" />
            <circle cx="263.108" cy="291.936" r="9.11671" fill="white" />
            <circle cx="263.108" cy="348.46" r="9.11671" fill="white" />
            <circle cx="263.108" cy="404.984" r="9.11671" fill="white" />
            <circle cx="314.161" cy="9.31837" r="9.11671" fill="white" />
            <circle cx="314.161" cy="65.8423" r="9.11671" fill="white" />
            <circle cx="314.161" cy="122.365" r="9.11671" fill="white" />
            <circle cx="314.161" cy="178.889" r="9.11671" fill="white" />
            <circle cx="314.161" cy="235.413" r="9.11671" fill="white" />
            <circle cx="314.161" cy="291.936" r="9.11671" fill="white" />
            <circle cx="314.161" cy="348.46" r="9.11671" fill="white" />
            <circle cx="314.161" cy="404.984" r="9.11671" fill="white" />
            <circle cx="365.215" cy="9.31837" r="9.11671" fill="white" />
            <circle cx="365.215" cy="65.8423" r="9.11671" fill="white" />
            <circle cx="365.215" cy="122.365" r="9.11671" fill="white" />
            <circle cx="365.215" cy="178.889" r="9.11671" fill="white" />
            <circle cx="365.215" cy="235.413" r="9.11671" fill="white" />
            <circle cx="365.215" cy="291.936" r="9.11671" fill="white" />
            <circle cx="365.215" cy="348.46" r="9.11671" fill="white" />
            <circle cx="365.215" cy="404.984" r="9.11671" fill="white" />
            <circle cx="416.268" cy="9.31837" r="9.11671" fill="white" />
            <circle cx="416.268" cy="65.8423" r="9.11671" fill="white" />
            <circle cx="416.268" cy="122.365" r="9.11671" fill="white" />
            <circle cx="416.268" cy="178.889" r="9.11671" fill="white" />
            <circle cx="416.268" cy="235.413" r="9.11671" fill="white" />
            <circle cx="416.268" cy="291.936" r="9.11671" fill="white" />
            <circle cx="416.268" cy="348.46" r="9.11671" fill="white" />
            <circle cx="416.268" cy="404.984" r="9.11671" fill="white" />
          </g>
        </g>
      </svg>

      <Container className="grid grid-cols-1 lg:grid-cols-2 gap-4 place-items-center mt-4">
        <div className="text-white text-right py-24  lg:hidden">
          <h1>Invest Anytime; Anywhere</h1>
          <p className="my-8 text-white">Download our android or ios app and start investing on your mobile today.</p>
          <div className="flex justify-end">
            <a className="mx-4" href="https://apps.apple.com/lk/app/optimo-investments/id1579837784#?platform=iphone"><img className="mx-4" src="https://firebasestorage.googleapis.com/v0/b/mf-optimo.appspot.com/o/appstore.png?alt=media&token=a7716442-b79d-490a-8072-8739b01d9959" alt="optimo-app-store" className="w-40" /></a>
            <a href="https://play.google.com/store/apps/details?id=com.optimoinvest.optimomobile"><img src="https://firebasestorage.googleapis.com/v0/b/mf-optimo.appspot.com/o/playstore.png?alt=media&token=9a543cd7-6b30-4208-8a89-ce15b88673c8" alt="optimo-playstore" className="w-40" /></a>
          </div>
        </div>
        <div className="overflow-y-scroll hide-scrollbar flex flex-col justify-evenly items-start" ref={testimoRef} >
          <img src="https://firebasestorage.googleapis.com/v0/b/mf-optimo.appspot.com/o/image1-removebg-preview.png?alt=media&token=0a293dc6-c16d-4387-8cf9-a367a651c5f1" />
        </div>
        <div className="text-white text-right py-32 hidden lg:block ">
          <h1>Invest Anytime; Anywhere</h1>
          <p className="my-8 text-white">Download our android or ios app and start investing on your mobile today.</p>
          <div className="flex justify-end">
            <a className="mx-4" href="https://apps.apple.com/lk/app/optimo-investments/id1579837784#?platform=iphone"><img className="mx-4" src="https://firebasestorage.googleapis.com/v0/b/mf-optimo.appspot.com/o/appstore.png?alt=media&token=a7716442-b79d-490a-8072-8739b01d9959" alt="optimo-app-store" className="w-40" /></a>
            <a href="https://play.google.com/store/apps/details?id=com.optimoinvest.optimomobile"><img src="https://firebasestorage.googleapis.com/v0/b/mf-optimo.appspot.com/o/playstore.png?alt=media&token=9a543cd7-6b30-4208-8a89-ce15b88673c8" alt="optimo-playstore" className="w-40" /></a>
          </div>
        </div>

      </Container>
    </div>


    {/* Newsletter */}
    <Container className=" py-32" >
      <div className="text-center" >
        <h2 className="font-bold text-4xl">Join Our Newsletter</h2>
        <h6 className="text-blue-900 text-lg">Latest news ,articles and updates montly delevered to your inbox.</h6>
      </div>

      <div className="max-w-xl mx-auto">
        <form action="#" className="mt-2">
          <div className="flex items-center">
            <input onInput={e => setNlEmail(e.target.value)} value={nlEmail} type="email" className="w-full px-2 py-4 mr-2  bg-gray-100 shadow-inner rounded-md border border-gray-400 focus:outline-none" required />
            <button onClick={async () => {
              if (nlEmail != null && nlEmail.includes('@')) {
                await firebase.firestore().collection('newsletterUsers').doc(nlEmail).set({
                  email: nlEmail
                })
                alert('Successfully added to Newsletter Group');
              }
            }} className="bg-blue-600 text-gray-200 px-5 py-2 rounded shadow " style={{ marginLeft: '-7.8rem' }}>Sign Up</button>
          </div>
        </form>
      </div>
    </Container>



    {/* Footer */}
    <Container>
      <div className="grid grid-cols-2  md:grid-cols-4 lg:grid-cols-5 my-8 gap-8 text-center md:text-left" ref={contactRef}>
        <div className="col-span-2 md:col-span-4 lg:col-span-1">
          <img src="https://firebasestorage.googleapis.com/v0/b/mf-optimo.appspot.com/o/logo-wide.png?alt=media&token=553c3ab7-8841-49ae-8477-ba1c907d5c40" alt="optimo-logo-wide" className="w-48" />
          <div className="text-left">
            <a href="mailto:info@optimoinvest.com" className="m-2">support@optimoinvest.com</a>
            <a href="https://apps.apple.com/lk/app/optimo-investments/id1579837784#?platform=iphone"><img src="https://firebasestorage.googleapis.com/v0/b/mf-optimo.appspot.com/o/appstore.png?alt=media&token=a7716442-b79d-490a-8072-8739b01d9959" alt="optimo-app-store" className="w-40" /></a>
            <a href="https://play.google.com/store/apps/details?id=com.optimoinvest.optimomobile"><img src="https://firebasestorage.googleapis.com/v0/b/mf-optimo.appspot.com/o/playstore.png?alt=media&token=9a543cd7-6b30-4208-8a89-ce15b88673c8" alt="optimo-playstore" className="w-40" /></a>
          </div>

        </div>

        <div >
          <h4 className="text-gray-600">Links</h4>
          <SimpleButton to={aboutRef}><p>Who we are</p></SimpleButton>
          <SimpleButton to={plansRef}><p>Plans</p></SimpleButton>
          <SimpleButton to={featuresRef}><p>Features</p></SimpleButton>
          <SimpleButton to={testimoRef}><p>Testimonials</p></SimpleButton>
          <SimpleButton to={paymentsRef}><p>Payment Methods</p></SimpleButton>
        </div>
        <div >
          <h4 className="text-gray-600">Included Documents</h4>
          <p><a href="/Optimo%20Privacy.html">Privacy Policy</a></p>
          <p><a href="/Optimo%20Terms.html">Terms and Conditions</a></p>
        </div>
        <div >
          <h4 className="text-gray-600">UK Branch</h4>
          <p className="font-bold">Optimo International Limited</p>
          <p>International House</p>
          <p>12 Constance Street</p>
          <p>London</p>
          <p>E16 2DQ</p>
          <p>+44 74 183 57637</p>
        </div>
        <div >
          <h4 className="text-gray-600">USA Branch</h4>
          <p>Optimo LLC</p>
          <p>312 W</p>
          <p>2nd St #1232 Casper</p>
          <p>WY 82601</p>
          <p>+14099083241</p>
        </div>

      </div>

    </Container>
    <p className="text-center">&copy; All rights reserved.</p>

  </div >)
}
export default Index


const Container = ({ children, className }) => {
  return <div className={`container mx-auto p-4  ${className}`}>{children}</div>
}



const ButtonPrimary = ({ children, className, onClick }) => {
  return <button onClick={onClick} className={`${className} cursor-pointer px-4 w-28 py-1 h-10 bg-blue-900 text-white rounded-md transition duration-200 ease-in-out hover:-translate-y-1 transform`}>{children}</button>
}
const ButtonOutline = ({ children, className, onClick }) => {
  return <button onClick={onClick} className={`${className}  cursor-pointer px-4 w-28 py-1  h-10  border-blue-900 border  text-blue-900  rounded-md transition duration-200 ease-in-out hover:-translate-y-1 transform`}>{children}</button>
}

const LinkButton = ({ children, className, to }) => {
  const scrollTo = () => {
    if (to) {
      to.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }
  return <button onClick={scrollTo} className={`${className} m-1 px-4 py-3 text-gray-400 font-semibold hover:text-blue-900 transition duration-200 ease-in-out hover:-translate-y-1 transform text-sm lg:text-base`}>{children}</button>
}
const SimpleButton = ({ children, className, to }) => {
  const scrollTo = () => {
    if (to) {
      to.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }
  return <><button onClick={scrollTo} className={`${className}`}>{children}</button><br /></>
}