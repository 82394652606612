import React from 'react'
import SectionTitle from '../components/section-title'
import Widget from '../components/widget'

const Index = () => (
  <>
    <SectionTitle title="" subtitle="" />
    <Widget title="" description=''>
      <p></p>
    </Widget>
  </>
)
export default Index
