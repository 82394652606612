import React, { useState } from 'react'
import Validation from '../../../components/forms/validation'
import Alert from '../../../components/alerts'
import { useEffect } from 'react'
import { useAuth } from '../../../hooks/useAuth'

const CalculatorInput
  = ({ message = null, onUpdataing }) => {
    const [data, onUpdate] = useState(null)

    useEffect(() => {
      if (data != null) {
        onUpdataing(data.calculatorInput)
      }
    }, [data])

    let items = [
      {
        label: 'Enter amount you wish to invest',
        error: { required: 'Please enter a value' },
        name: 'calculatorInput',
        type: 'number',
      },
    ]
    return (
      <div className="flex flex-col md:w-1/2 py-16 px-8 border-t border-b border-white rounded-lg container mx-auto">
          <div className=" rounded-lg p-8  text-left bg-white shadow-xl  transition duration-200 ease-in-out hover:-translate-y-2 transform cursor-pointer">
            <h4> If you need to invest custom amount, you can use our Calculator.</h4>
            <br/>
          <Validation items={items} onSubmit={onUpdate} showButton={true}/>
        </div>
      </div>
    )
  }

export default CalculatorInput

