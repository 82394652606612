
import React from 'react'
import { Redirect, useLocation } from 'react-router-dom'

const Index = () => {
  const location = useLocation()
  const localStorage = window.localStorage;
  if(location.search!=null) {
    window.location.href = `https://my.optimoinvest.com/invest?${location.search}`; 
  } else {
    window.location.href = `https://my.optimoinvest.com`; 
  }
}
export default Index